import React from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";

import { useWizard } from "../wizard/WizardContext";

const AddressField = ({ itemId }) => {
  const { responses, updateResponse } = useWizard();

  return (
    <FormControl fullWidth>
      <TextField
        value={responses[itemId]?.AnswerDescription || ""}
        onChange={(e) => updateResponse(itemId, { AnswerDescription: e.target.value })}
        InputProps={{
          startAdornment: (
            <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ flexShrink: "0" }}>
              <path fillRule="evenodd" clipRule="evenodd" d="M11.5 8.50051C11.5 7.11924 10.3808 6 9.00051 6C7.61924 6 6.5 7.11924 6.5 8.50051C6.5 9.88076 7.61924 11 9.00051 11C10.3808 11 11.5 9.88076 11.5 8.50051Z" stroke="#485776" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path fillRule="evenodd" clipRule="evenodd" d="M8.99951 19C7.80104 19 1.5 13.8984 1.5 8.56329C1.5 4.38664 4.8571 1 8.99951 1C13.1419 1 16.5 4.38664 16.5 8.56329C16.5 13.8984 10.198 19 8.99951 19Z" stroke="#485776" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          ),
        }}
        sx={{
          "& .MuiInputBase-root": {
            border: "1px solid #dddee1",
            borderRadius: "8px",
            background: "none",
          },
          "& .MuiInputBase-input": {
            p: 2.5,
            fontSize: "0.875rem",
            lineHeight: "1.57",
          },
        }}
      />
    </FormControl>
  );
}

export default AddressField;
