import React from "react";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";

import { WIZARD } from "../../locales/namespaces";

const QuestionTitle = ({ title, main }) => {
  const { t } = useTranslation(WIZARD);

  return (
    <Typography
      component="h2"
      variant="h4"
      align={main ? "center" : "inherit"}
      mb={main ? 8 : 4}
      sx={{
        fontSize: "18px",
        lineHeight: "24px",
      }}
    >
      {t(title)}
    </Typography>
  );
}

export default QuestionTitle;
