import React from "react";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";

import { WIZARD } from "../../locales/namespaces";

const SuccessMessage = ({ message }) => {
  const { t } = useTranslation(WIZARD);

  return (
    <Alert
      icon={
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M18.778 0.666016H7.21935C3.19135 0.666016 0.666016 3.51802 0.666016 7.55402V18.4447C0.666016 22.4807 3.17935 25.3327 7.21935 25.3327H18.7767C22.818 25.3327 25.3327 22.4807 25.3327 18.4447V7.55402C25.3327 3.51802 22.818 0.666016 18.778 0.666016Z" stroke="#519C66" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M8.25195 12.999L11.4173 16.163L17.7453 9.83496" stroke="#519C66" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      }
      sx={{
        p: 2,
        fontSize: "15px",
        lineHeight: "24px",
        border: "1px solid #519C66",
        borderRadius: "8px",
        backgroundColor: "#519C660D",
      }}
    >
      {t(message)}
    </Alert>
  );
}

export default SuccessMessage;
