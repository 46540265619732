import React from "react";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";

import { WIZARD } from "../../../locales/namespaces";
import { useWizard } from "../WizardContext";

const LEVELS = {
  "0": "Still have questions? Schedule an appointment.",
  "1": "Still have questions? Schedule an appointment.",
  "2": "Proceed with your request and a consultant will contact you to address all your questions!",
  "3": "The amount you have requested can be approved! Proceed with the application!",
}

const CtaMessage = () => {
  const { t } = useTranslation(WIZARD);
  const { summary } = useWizard();

  return (
    <Alert
      severity="info"
      icon={
        <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M13.5247 0.666016C20.3354 0.666016 25.8581 6.18735 25.8581 12.9993C25.8581 19.81 20.3354 25.3327 13.5247 25.3327C6.71274 25.3327 1.19141 19.81 1.19141 12.9993C1.19141 6.18735 6.71274 0.666016 13.5247 0.666016Z" stroke="#0BC1B6" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M13.5182 7.9375V13.8295" stroke="#0BC1B6" strokeWidth="1.64516" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M13.5194 18.0632H13.5327" stroke="#0BC1B6" strokeWidth="2.19355" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      }
      sx={{
        fontWeight: "600",
        border: "1px solid #0BC1B6",
        borderRadius: "8px",
        backgroundColor: "#0BC1B60D",
      }}
    >
      {t(LEVELS[summary.approval_level])}
    </Alert>
  );
}

export default CtaMessage;
