import React from "react";
import Stack from "@mui/material/Stack";
import Divider from '@mui/material/Divider';
import Typography from "@mui/material/Typography";
import { visuallyHidden } from '@mui/utils';

import { useWizard } from "../WizardContext";
import { useTranslation } from "react-i18next";
import { WIZARD } from "../../../locales/namespaces";
import useUserData from "../../../../hooks/user/useUserData";

const LoanSummary = () => {
  const { t } = useTranslation(WIZARD);
  const { responses } = useWizard();
  const { language } = useUserData();

  const loanAmount = new Intl.NumberFormat(language, {
    style: "currency",
    currency: "EUR",
    maximumFractionDigits: 0,
  }).format(responses["10"].AnswerDescription);

  const ltv = Math.round(
    (responses["10"].AnswerDescription / responses["3"].AnswerDescription) * 100
  );

  return (
    <Stack
      direction="row"
      divider={<Divider orientation="vertical" flexItem />}
      sx={{
        border: "1px solid #dddee1",
        borderRadius: "8px",
        backgroundColor: "#ffffff",
        boxShadow: "0px 2.94px 23.49px 0px #3D467026",
      }}
    >
      <Stack
        spacing={1}
        sx={{
          flex: 1,
          alignItems: "center",
          p: { xs: "49px 16px", sm: "49px 24px" },
        }}
      >
        <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17 7.0627V1.7627H14V4.3627L10 0.762695L0 9.7627H3V17.7627H9V11.7627H11V17.7627H17V9.7627H20L17 7.0627ZM15 15.7627H13V9.7627H7V15.7627H5V7.95269L10 3.4527L15 7.95269V15.7627Z" fill="#0BC1B6" />
          <path d="M8 7.7627H12C12 6.6627 11.1 5.7627 10 5.7627C8.9 5.7627 8 6.6627 8 7.7627Z" fill="#0BC1B6" />
        </svg>
        <Typography sx={{ color: "#8F96A9" }}>
          {t("Loan Amount")}
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: "30px", sm: "35px" },
            lineHeight: "46px",
          }}
        >
          {loanAmount}
        </Typography>
      </Stack>
      <Stack
        sx={{ flex: 1, alignItems: "center", justifyContent: "center", p: 2 }}
      >
        <Typography sx={{ textAlign: "center", color: "#8F96A9" }}>
          {t("Loan-to-Value Ratio")}
        </Typography>
        <Typography
          sx={{
            position: "relative",
            width: "90px",
            height: "90px",
            marginTop: 2,
            borderRadius: "50%",
            background: `conic-gradient(#0FBA2B ${ltv * 3.6}deg, #F6F8FC 0deg)`,
            fontSize: "24px",
            lineHeight: "30px",
            "&::before": {
              content: `"${ltv}%"`,
              position: "absolute",
              inset: "4px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#ffffff",
              borderRadius: "50%",
            }
          }}
        >
          <span style={visuallyHidden}>{ltv}%</span>
        </Typography>
      </Stack>
    </Stack>
  );
}

export default LoanSummary;
