import React from "react";
import Stack from "@mui/material/Stack";

import { useWizard } from "../WizardContext";
import QuestionTitle from "../../elements/QuestionTitle";
import RadioOptions from "../../elements/RadioOptions";
import SelectField from "../../elements/SelectField";

const Demographics = () => {
  const { data } = useWizard();

  return (
    <Stack spacing={8}>
      <div>
        <QuestionTitle title="Marital status" main />
        <RadioOptions
          itemId="11"
          options={data?.filter((data) => data.ItemId === "11")}
        />
      </div>
      <div>
        <QuestionTitle title="Dependents" />
        <SelectField
          itemId="12"
          options={data?.filter((data) => data.ItemId === "12")}
        />
      </div>
    </Stack>
  );
}

export default Demographics;
