import React from "react";
import Stack from "@mui/material/Stack";

import { useWizard } from "../WizardContext";
import QuestionTitle from "../../elements/QuestionTitle";
import RadioOptions from "../../elements/RadioOptions";
import InfoMessage from "../../elements/InfoMessage";

const infoMessages = {
  "8": "There is no time to waste! It would be a good idea to proceed immediately with a mortgage loan application!",
  "9": "There is no time to waste! It would be a good idea to proceed immediately with a mortgage loan application!",
  "10": "Now is the right time to take the next step, applying for a mortgage.",
  "11": "It’s a good idea to plan your next steps. By knowing how much money you can borrow, you will be able to chose the property that suits you.",
}

const Months = () => {
  const { data, responses } = useWizard();

  return (
    <>
      <QuestionTitle
        title="In how many months do you want to purchase the property?"
        main
      />
      <Stack spacing={3}>
        <RadioOptions
          itemId="5"
          options={data?.filter((data) => data.ItemId === "5")}
        />
        {Boolean(responses["5"]?.AnswerId) && (
          <InfoMessage message={infoMessages[responses["5"].AnswerId]} />
        )}
      </Stack>
    </>
  );
}

export default Months;
