import React from "react";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";

import { WIZARD } from "../../locales/namespaces";

const ErrorMessage = ({ message }) => {
  const { t } = useTranslation(WIZARD);

  return (
    <Alert
      severity="error"
      icon={
        <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M3.41814 23.249H22.5955C24.7048 23.249 26.0288 20.969 24.9808 19.137L15.3995 2.38367C14.3448 0.539667 11.6861 0.538333 10.6301 2.38233L1.0328 19.1357C-0.0151962 20.9677 1.30747 23.249 3.41814 23.249Z" stroke="#CC5F5F" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M13.0026 13.8863V9.75293" stroke="#CC5F5F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M12.992 17.9997H13.0054" stroke="#CC5F5F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      }
      sx={{
        p: 2,
        fontSize: "15px",
        lineHeight: "24px",
        border: "1px solid #CC5F5F",
        borderRadius: "8px",
        backgroundColor: "#CC5F5F0D",
      }}
    >
      {t(message)}
    </Alert>
  );
}

export default ErrorMessage;
