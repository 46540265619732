import React from "react";
import { useTranslation } from "react-i18next";
import Container from "@mui/material/Container";

import { LANDING } from "../../../locales/namespaces";
import ApplyOnlineButton from "../../elements/ApplyOnlineButton";

import im1 from "../../../../assets/images/landing/1.png"
import im2 from "../../../../assets/images/landing/2.png"
import im3 from "../../../../assets/images/landing/3.png"
import im4 from "../../../../assets/images/landing/4.png"
import im5 from "../../../../assets/images/landing/5.png"

import styles from "./bankLogos.module.css";

const images = [im1, im2, im3, im4, im5];

const BankLogos = () => {
  const { t } = useTranslation(LANDING);

  return (
    <section className={styles.section}>
      <svg viewBox="0 0 1440 101" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" className={styles.deco}>
        <path d="M1440 74.6778V100.341H0V76.3886C415.95 60.4205 1260.73 4.02805 1312.28 2.20312C1376.7 -0.0780334 1400.23 -2.64008 1419.65 15.8901C1439.62 34.9322 1439.77 65.4746 1440 74.6778Z" fill="#F6F8FC" />
      </svg>
      <div className={styles.content}>
        <Container maxWidth="sm">
          <div className={styles.logos}>
            {images.map((image, index) => {
              return (
                <img key={index} src={image} alt="" width="224" height="170" loading="lazy" className={styles.image} />
              );
            })}
          </div>
          <div className={styles.text}>
            {t("We collaborate with the largest financial institutions in Greece, providing you with the best possible options for mortgage products.")}
          </div>
          <ApplyOnlineButton />
        </Container>
      </div>
    </section>
  )
}

export default BankLogos;
