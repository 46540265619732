import React from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";

import { useWizard } from "../wizard/WizardContext";

const NumberField = ({ itemId }) => {
  const { responses, updateResponse } = useWizard();

  const handleChange = (e) => {
    const number = e.target.value.replace(/[^0-9]/g, "");
    const value = number ? { AnswerDescription: Number(number) } : undefined;

    updateResponse(itemId, value);
  }

  return (
    <FormControl fullWidth>
      <TextField
        value={responses[itemId]?.AnswerDescription || ""}
        onChange={handleChange}
        sx={{
          "& .MuiInputBase-root": {
            border: "1px solid #dddee1",
            borderRadius: "8px",
            background: "none",
          },
          "& .MuiInputBase-input": {
            p: 3,
            fontSize: "0.875rem",
            lineHeight: "1.57",
          },
        }}
      />
    </FormControl>
  );
}

export default NumberField;
