// MARK: Questions
export const QUESTIONS = {
  "1": {
    title: "Ποιος θα είναι ο σκοπός του δανείου ;",
    required: true,
  },
  "2": {
    title: "Έχετε βρει ακίνητο ;",
    required: false,
  },
  "3": {
    title: "Ποια είναι η αξία του ακινήτου που θέλετε να αποπερατώσετε;",
    required: true,
  },
  "4": {
    title: "Περιοχή Ακινήτου",
    required: false,
  },
  "5": {
    title: "Σε πόσους μήνες θέλετε να αγοράσετε το ακίνητο ;",
    required: false,
  },
  "6": {
    title: "Ποια είναι η χώρα κατοικίας σας;",
    required: false,
  },
  "7": {
    title: "Ποια είναι η υπηκοότητα σας;",
    required: true,
  },
  "8": {
    title: "Είστε φορολογικός κάτοικος..",
    default: { "AnswerId": "16", "AnswerDescription": "Ελλάδα\r\n" },
    required: true,
  },
  "9": {
    title: "Ποια είναι η ηλικία σας;",
    required: true,
  },
  "10": {
    title: "Τι ποσό δανείου επιθυμείτε να αιτηθείτε ;",
    required: true,
  },
  "11": {
    title: "Οικογενειακή κατάσταση",
    required: true,
  },
  "12": {
    title: "Εξαρτώμενα μέλη",
    default: { "AnswerDescription": "0", "AnswerId": "56" },
    required: true,
  },
  "13": {
    title: "Ποιο είναι το ετήσιο καθαρό σας εισόδημα (καταχωρείστε αθροιστικά τα εισοδήματα όλων των οφειλετών αν είναι πάνω από ένας)",
    required: true,
  },
  "14": {
    title: "Έχετε άλλες δανειακές υποχρεώσεις;",
    required: true,
  },
  "15": {
    title: "Kαταχωρείστε αθροιστικά το ποσό των μηνιαίων δόσεων που πληρώνετε.",
    required: false,
  },
  "16": {
    title: "Έχετε πιστωτικές κάρτες ή ανοιχτά δάνεια; Αν ναι ποιο είναι το πιστωτικό τους όριο αθροιστικά ;",
    required: false,
  },
};

// MARK: All steps
export const ALL_STEPS = [
  {
    id: 1,
    name: "purpose",
    active: (responses) => true,
    validation: "",
    questions: ["1"],
  },
  {
    id: 2,
    name: "have_property",
    active: (responses) =>
      responses["1"]?.AnswerId === "1" || responses["1"]?.AnswerId === "2",
    validation: "",
    questions: ["2"],
  },
  {
    id: 3,
    name: "value",
    active: (responses) => true,
    validation: "",
    questions: ["3"],
  },
  {
    id: 4,
    name: "area",
    active: (responses) => true,
    validation: "",
    questions: ["4"],
  },
  {
    id: 5,
    name: "months",
    active: (responses) =>
      responses["1"]?.AnswerId === "1" ||
      responses["1"]?.AnswerId === "2" ||
      responses["1"]?.AnswerId === "3",
    validation: "",
    questions: ["5"],
  },
  {
    id: 6,
    name: "country",
    active: (responses) => true,
    validation: "1",
    questions: ["6", "7", "8"],
  },
  {
    id: 7,
    name: "age",
    active: (responses) => true,
    validation: "2",
    questions: ["9"],
  },
  {
    id: 8,
    name: "loan_amount",
    active: (responses) => true,
    validation: "3",
    questions: ["10"],
  },
  {
    id: 9,
    name: "demographics",
    active: (responses) => true,
    validation: "",
    questions: ["11", "12"],
  },
  {
    id: 10,
    name: "finance",
    active: (responses) => true,
    validation: "",
    questions: ["13", "14", "15", "16"],
  },
];


// MARK: OTP length
export const OTP_LENGTH = 6;
