import React from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { NumericFormat } from "react-number-format";

import { useWizard } from "../wizard/WizardContext";
import useUserData from "../../../hooks/user/useUserData";

const AmountField = ({ itemId }) => {
  const { responses, updateResponse } = useWizard();
  const { thousandSeparator, decimalSeparator } = useUserData();

  const handleChange = (values) => {
    const value = values.value
      ? { AnswerDescription: Number(values.value) }
      : undefined;

    updateResponse(itemId, value);
  }

  return (
    <FormControl fullWidth>
      <NumericFormat
        customInput={TextField}
        thousandSeparator={thousandSeparator}
        decimalSeparator={decimalSeparator}
        value={responses[itemId]?.AnswerDescription ?? ""}
        onValueChange={handleChange}
        InputProps={{
          startAdornment: (
            <Typography sx={{ fontWeight: "600" }}>€</Typography>
          ),
        }}
        sx={{
          "& .MuiInputBase-root": {
            border: "1px solid #dddee1",
            borderRadius: "8px",
            background: "none",
          },
          "& .MuiInputBase-input": {
            p: 2,
            fontSize: "0.875rem",
            lineHeight: "1.57",
          },
        }}
      />
    </FormControl>
  );
}

export default AmountField;
