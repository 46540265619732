import React from "react";
import Stack from "@mui/material/Stack";

import { useWizard } from "../WizardContext";
import QuestionTitle from "../../elements/QuestionTitle";
import RadioOptions from "../../elements/RadioOptions";
import SelectField from "../../elements/SelectField";

const Country = () => {
  const { data } = useWizard();

  return (
    <Stack spacing={8}>
      <div>
        <QuestionTitle title="What is your country of residence?" />
        <RadioOptions
          itemId="6"
          options={data?.filter((data) => data.ItemId === "6")}
        />
      </div>
      <div>
        <QuestionTitle title="What is your nationality?" />
        <RadioOptions
          itemId="7"
          options={data?.filter((data) => data.ItemId === "7")}
        />
      </div>
      <div>
        <QuestionTitle title="Are you a tax resident of..." />
        <SelectField
          itemId="8"
          options={data?.filter((data) => data.ItemId === "8")}
        />
      </div>
    </Stack>
  );
}

export default Country;
