import React from "react"
import Container from '@mui/material/Container';
import MuiLink from "@mui/material/Link";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";

import Logo from "../../../../theme/mantis/components/logo";
import ApplyOnlineButton from '../../elements/ApplyOnlineButton';
import ProfileIcon from "../../../../assets/images/icons/profileIcon";
import Localization from "../../../../theme/mantis/layout/mainLayout/header/headerContent/localization";
import { getRouteUrl } from "../../../../helpers/getRouteUrl";
import { ROUTE_PAGE_LOGIN, ROUTE_PAGE_SIGN_UP } from "../../../../routers/routes";

import styles from "./headerLanding.module.css";

const HeaderLanding = () => {
  return (
    <Container component="header" maxWidth="xl" className={styles.container}>
      <Box sx={{ color: "common.white", bgcolor: "text.primary" }} className={styles.header}>
        <div className={styles.ctas}>
          <span className={styles.logo}>
            <Logo />
          </span>
          <ApplyOnlineButton />
        </div>
        <div className={styles.actions}>
          <div className={styles.profile}>
            <ProfileIcon className={styles.profileIcon} />
            <span>
              <MuiLink component={Link} to={getRouteUrl(ROUTE_PAGE_LOGIN)}>
                Log In
              </MuiLink>
              {" / "}
              <MuiLink component={Link} to={getRouteUrl(ROUTE_PAGE_SIGN_UP)}>
                Sign Up
              </MuiLink>
            </span>
          </div>
          <Localization compact light />
        </div>
      </Box>
      <svg viewBox="0 0 393 35" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.deco} aria-hidden="true">
        <path d="M0 9.14395V0.353149H393V8.5579C279.48 14.0277 48.9244 33.3448 34.8579 33.9699C17.2747 34.7513 10.8549 35.629 5.55259 29.2815C0.103824 22.7587 0.0625529 12.2965 0 9.14395Z" fill="#485776" />
      </svg>
    </Container>
  )
}

export default HeaderLanding;
