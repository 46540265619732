import React from "react";
import { useTranslation } from "react-i18next";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";

import { LANDING } from "../../../locales/namespaces";
import Logo from "../../../../theme/mantis/components/logo";
import ApplyOnlineButton from "../../elements/ApplyOnlineButton";
import AppStoresButtons from "../../elements/AppStoresButtons";
import { getRouteUrl } from "../../../../helpers/getRouteUrl";
import { ROUTE_PAGE_UNAUTHORIZED_CALCULATION } from "../../../../routers/routes";

import styles from "./footer.module.css";

const Footer = () => {
  const { t } = useTranslation(LANDING);

  const buttonStyle = {
    p: "8px",
    fontSize: "13px",
    background: "none",
    borderRadius: "8px",
    "&:hover": {
      borderWidth: "1px",
      borderColor: "inherit",
      borderRadius: "8px",
      background: "none",
    }
  };

  return (
    <footer className={styles.footer}>
      <svg viewBox="0 0 1440 56" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" className={styles.deco}>
        <path d="M0 41.1781V55.1924H1440V42.1124C1024.05 33.3923 179.265 2.59701 127.724 1.60044C63.2965 0.354721 39.7736 -1.04438 20.3454 9.07474C0.380423 19.4734 0.229201 36.1523 0 41.1781Z" fill="#485776" />
      </svg>
      <div className={styles.content}>
        <Container>
          <Grid container rowGap="64px">
            <Grid item xs={12} sm={4} className={styles.left}>
              <Stack spacing={3} sx={{ maxWidth: "220px" }}>
                <Button
                  variant="outlined"
                  color="info"
                  component={Link}
                  to="tel:+302109520047"
                  sx={buttonStyle}
                >
                  <svg width="31" height="32" viewBox="0 0 31 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.3516 3.875C23.0834 4.40048 26.8218 8.13379 27.3524 12.8656" stroke="#0BC1B6" strokeWidth="1.18018" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M18.3516 8.40527C20.6158 8.84509 22.3853 10.6159 22.8264 12.8801" stroke="#0BC1B6" strokeWidth="1.18018" strokeLinecap="round" strokeLinejoin="round" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M14.1047 16.625C19.2047 21.7238 20.3617 15.8251 23.609 19.0701C26.7395 22.1999 28.5402 22.8269 24.5724 26.7923C24.0756 27.1916 20.9189 31.9953 9.82538 20.9046C-1.26955 9.8125 3.5313 6.65254 3.93069 6.15584C7.90669 2.17947 8.5243 3.98928 11.6549 7.11905C14.9007 10.3655 9.00465 11.5263 14.1047 16.625Z" stroke="#0BC1B6" strokeWidth="1.18018" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  <div style={{ marginLeft: 10, color: "#fff" }}>
                    <Box sx={{ marginBottom: "10px", fontSize: "10px" }}>{t("Phone Support")}</Box>
                    <Box sx={{ fontSize: "17px" }}>210 9520047-48</Box>
                  </div>
                </Button>
                <Button
                  variant="outlined"
                  color="info"
                  component="a"
                  href="https://ims-fc.gr"
                  target="_blank"
                  sx={buttonStyle}
                >
                  {t("Learn More")}
                </Button>
                <Button
                  variant="outlined"
                  color="info"
                  component={Link}
                  to={getRouteUrl(ROUTE_PAGE_UNAUTHORIZED_CALCULATION)}
                  sx={buttonStyle}
                >
                  {t("Calculation of Mortgage Installment")}
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={4} className={styles.center}>
              <Stack spacing={{ xs: 3, sm: 7 }}>
                <div className={styles.logo}>
                  <Logo />
                </div>
                <div>
                  <ApplyOnlineButton />
                </div>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={4} className={styles.right}>
              <AppStoresButtons direction="column" />
            </Grid>
          </Grid>
        </Container>
      </div>
    </footer>
  )
}

export default Footer;
