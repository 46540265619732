import Home from "../pages/home/home";
import {routeCreator} from "../helpers/routeCreator";
import Login from "../pages/login/login";
import ResetPassword from '../pages/reset-password/resetPassword'
import SignUp from '../pages/signUpForm/signUpForm'
import Create from "../modules/calculations/pages/create/create";
import PrivacyPolicy from "../pages/privacyPolicy/privacyPolicy";
import TermsConditions from "../pages/termsConditions/termsConditions";
import Index from "../thinkPlus";
import Landing from "../thinkPlus/landing";

export const ROUTE_PAGE_RESET_PASSWORD = routeCreator(ResetPassword, '/reset', 'reset', 'ResetPassword', {exact: true})
export const ROUTE_PAGE_SIGN_UP = routeCreator(SignUp, '/signup', 'signup', 'SignUp', {exact: true})
export const ROUTE_PAGE_UNAUTHORIZED_CALCULATION = routeCreator(Create, '/fast/calculation', 'Fast Calculation', 'page.fast.calculation', {exact: true})
export const ROUTE_PAGE_LOGIN = routeCreator(Login, '/', 'login', 'Login', 'page.login')
export const ROUTE_PAGE_HOME = routeCreator(Home, '/', 'Home', 'page.home', {exact: true})
export const ROUTE_PAGE_PRIVACY_POLICY = routeCreator(PrivacyPolicy, '/privacy/policy', 'privacy', 'PrivacyPolicy', {exact: true})
export const ROUTE_PAGE_TERMS_CONDITIONS = routeCreator(TermsConditions, '/terms/conditions', 'terms', 'TermsConditions', {exact: true})
export const ROUTE_PAGE_WIZARD = routeCreator(Index, '/wizard', 'wizard', 'Wizard', 'page.wizard')
export const ROUTE_PAGE_LANDING = routeCreator(Landing, '/landing', 'landing', 'Landing', 'page.landing')

const router = {
	ROUTE_PAGE_HOME,
	ROUTE_PAGE_PRIVACY_POLICY,
	ROUTE_PAGE_TERMS_CONDITIONS
}

export default router;