import React from "react";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
import AlertTitle from '@mui/material/AlertTitle';

import { WIZARD } from "../../locales/namespaces";

const InfoMessage = ({ title, message }) => {
  const { t } = useTranslation(WIZARD);

  return (
    <Alert
      severity="info"
      icon={
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M10.002 0.75C15.11 0.75 19.252 4.891 19.252 10C19.252 15.108 15.11 19.25 10.002 19.25C4.89295 19.25 0.751953 15.108 0.751953 10C0.751953 4.891 4.89295 0.75 10.002 0.75Z" stroke="#485776" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M9.99609 6.20312V10.6221" stroke="#485776" strokeWidth="1.64516" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M9.995 13.7969H10.005" stroke="#485776" strokeWidth="2.19355" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      }
      sx={{
        fontSize: "12px",
        lineHeight: "18px",
        backgroundColor: "#4857760D",
      }}
    >
      {title && (
        <AlertTitle
          sx={{
            marginTop: "6px",
            fontWeight: "600",
            fontSize: "12px",
            lineHeight: "18px",
          }}
        >
          {t(title)}
        </AlertTitle>
      )}
      {t(message)}
    </Alert>
  );
}

export default InfoMessage;
