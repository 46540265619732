import React from "react";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";

import { WIZARD } from "../../locales/namespaces";

const WarningMessage = ({ message }) => {
  const { t } = useTranslation(WIZARD);

  return (
    <Alert
      severity="warning"
      icon={
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M15.9993 28.333C22.81 28.333 28.3327 22.8117 28.3327 15.9997C28.3327 9.18901 22.81 3.66634 15.9993 3.66634C9.18735 3.66634 3.66602 9.18901 3.66602 15.9997C3.66602 22.8117 9.18735 28.333 15.9993 28.333Z" stroke="#F4A40B" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M15.9909 21.0625V15.1705" stroke="#F4A40B" strokeWidth="1.64516" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M15.992 10.9368H16.0054" stroke="#F4A40B" strokeWidth="2.19355" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      }
      sx={{
        p: 2,
        fontSize: "15px",
        lineHeight: "24px",
        border: "1px solid #F4A40B",
        borderRadius: "8px",
        backgroundColor: "#F4A40B0D",
      }}
    >
      {t(message)}
    </Alert>
  );
}

export default WarningMessage;
