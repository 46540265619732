import React from "react";

import { useWizard } from "../WizardContext";
import QuestionTitle from "../../elements/QuestionTitle";
import RadioOptions from "../../elements/RadioOptions";

const Purpose = () => {
  const { data } = useWizard();

  return (
    <>
      <QuestionTitle title="What will be the purpose of the loan?" main />
      <RadioOptions itemId="1" options={data?.filter((data) => data.ItemId === "1")} />
    </>
  );
}

export default Purpose;
