// @flow
import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

import { useWizard, WizardProvider } from "./components/wizard/WizardContext";
import AuthBackgroundImage from "../assets/images/components/authBackgroundImage";
import ProgressBar from "./components/wizard/ProgressBar";
import FormGrid from "./components/wizard/FormGrid";
import Summary from "./components/wizard/Summary";
import ImportCompletedMessage from "./components/wizard/ImportCompletedMessage";

const WizardScreen = () => {
	const { summary, importCompleted } = useWizard();

	return (
		<Box sx={{ position: "relative", minHeight: "calc(100dvh - 122px)" }}>
			<AuthBackgroundImage />
			<Container
				maxWidth="md"
				sx={{ position: "relative", pb: 8, zIndex: "0" }}
			>
				{importCompleted ? (
					<Box sx={{ pt: 8 }}>
						<ImportCompletedMessage />
					</Box>
				) : (
					<>
						<Box sx={{ py: 4 }}>
							<ProgressBar />
						</Box>
						{!summary?.message ? (
							<FormGrid />
						) : (
							<Summary />
						)}
					</>
				)}
			</Container>
		</Box>
	);
};

const Index = () => {
	return (
		<WizardProvider>
			<WizardScreen />
		</WizardProvider>
	);
};

export default Index;
