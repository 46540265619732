import React from "react";
import { Box } from '@mui/material';

import { useWizard } from "./WizardContext";

const ProgressBar = () => {
  const { currentStep, steps } = useWizard();

  const progress = ((currentStep + 1) / steps.length) * 100;

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "11px",
        borderRadius: "100px",
        backgroundColor: "#dddee1",
        overflow: "hidden",
        "&::after": {
          content: '""',
          position: "absolute",
          top: "0",
          left: "0",
          width: "var(--progress)",
          height: "100%",
          backgroundColor: "#0bc1b6",
          transition: "width 0.15s ease-out",
        }
      }}
      style={{ "--progress": `${progress}%` }}
    />
  );
}

export default ProgressBar;
