import React from "react";
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import { WIZARD } from "../../locales/namespaces";
import { getRouteUrl } from "../../../helpers/getRouteUrl";
import { ROUTE_PAGE_CALCULATIONS_INDEX } from "../../../modules/calculations/routes/routes";

const ImportCompletedMessage = () => {
  const { t } = useTranslation(WIZARD);

  return (
    <Stack sx={{
      alignItems: "center",
      p: "64px 32px",
      borderBottom: "2px solid #0BC1B6",
      borderRadius: "12px",
      backgroundColor: "#ffffff",
      boxShadow: "0px 4px 32px 0px #3D467026",
    }}>
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 21H3C2.46957 21 1.96086 20.7893 1.58579 20.4142C1.21071 20.0391 1 19.5304 1 19V12C1 11.4696 1.21071 10.9609 1.58579 10.5858C1.96086 10.2107 2.46957 10 3 10H6M13 8V4C13 3.20435 12.6839 2.44129 12.1213 1.87868C11.5587 1.31607 10.7956 1 10 1L6 10V21H17.28C17.7623 21.0055 18.2304 20.8364 18.5979 20.524C18.9654 20.2116 19.2077 19.7769 19.28 19.3L20.66 10.3C20.7035 10.0134 20.6842 9.72068 20.6033 9.44225C20.5225 9.16382 20.3821 8.90629 20.1919 8.68751C20.0016 8.46873 19.7661 8.29393 19.5016 8.17522C19.2371 8.0565 18.9499 7.99672 18.66 8H13Z" stroke="#0BC1B6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
      <Typography
        sx={{
          width: "498px",
          maxWidth: "100%",
          mx: "auto",
          mt: 3,
          fontSize: "18px",
          lineHeight: "24px",
          fontWeight: "600",
          textAlign: "center",
        }}
      >
        {t("Your application has been successfully submitted, please expect communication from an IMS consultant")}
      </Typography>
      <Stack
        direction={{ sm: "row" }}
        sx={{
          alignItems: "center",
          mt: 8,
          gap: { xs: 3, sm: 6 }
        }
        }
      >
        <Button
          component="a"
          href="https://ims-fc.gr"
          target="_blank"
          variant="contained"
          color="info"
          sx={{ p: "12px 32px" }}
        >
          {t("Learn more")}
        </Button>
        <Button
          component={Link}
          to={getRouteUrl(ROUTE_PAGE_CALCULATIONS_INDEX)}
          variant="contained"
          color="info"
          sx={{ p: "12px 32px" }}
        >
          {t("Loan Installment Calculation")}
        </Button>
      </Stack>
    </Stack>
  );
}

export default ImportCompletedMessage;
