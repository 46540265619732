import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";

import { WIZARD } from "../../locales/namespaces";
import { useWizard } from "./WizardContext";
import { QUESTIONS } from "./constants";

const buttonStyle = {
  p: "8px 16px",
  fontSize: "13px",
  "&:hover": {
    borderWidth: "1px",
    backgroundColor: "#0BC1B61A",
  }
};

const Pagination = () => {
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const { t } = useTranslation(WIZARD);
  const {
    currentStep,
    setCurrentStep,
    steps,
    validateResponses,
    calculateResponses,
    validation,
    responses,
  } = useWizard();

  const goToNext = () => {
    setCurrentStep((prevIndex) => Math.min(prevIndex + 1, steps.length - 1));
  };

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      if (steps[currentStep].validation) {
        setLoading(true);
        validateResponses({
          onSuccess: goToNext
        }).finally(() => setLoading(false));
      } else {
        goToNext();
      }
    } else {
      setLoading(true);
      calculateResponses().finally(() => setLoading(false));
    }
  };

  const handlePrevious = () => {
    setCurrentStep((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  useEffect(() => {
    const required = steps[currentStep].questions.filter(
      (q) => QUESTIONS[q].required
    );
    const hasUnanwsered = required.some((r) => !responses[r]);
    setDisabled(hasUnanwsered);
  }, [currentStep, responses, steps]);

  return (
    <Box
      sx={{
        display: "flex",
        gap: 3,
        marginTop: 8
      }}
    >
      {currentStep > 0 && (
        <Button
          variant="outlined"
          color="info"
          sx={buttonStyle}
          onClick={handlePrevious}
        >
          {t("Previous")}
        </Button>
      )}
      <LoadingButton
        variant="outlined"
        color="info"
        sx={{ ...buttonStyle, ml: "auto" }}
        onClick={handleNext}
        disabled={!!validation || disabled || loading}
        loading={loading}
      >
        {t("Next")}
      </LoadingButton>
    </Box>
  );
}

export default Pagination;
