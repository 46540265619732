import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import agent from "../../../assets/images/agent.png";
import Sidebar from "./Sidebar";
import MultiStepForm from "./MultiStepForm";

const FormGrid = () => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={8}>
        <Box sx={{ p: 4, bgcolor: "#fff", borderRadius: 3 }}>
          <Box sx={{ textAlign: "center", mb: 3 }}>
            <img src={agent} width="80" height="80" alt="" />
          </Box>
          <MultiStepForm />
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        sm={4}
        sx={{ display: { xs: "none", sm: "block" } }}
      >
        <Sidebar />
      </Grid>
    </Grid>
  );
}

export default FormGrid;
