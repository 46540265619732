import React from "react";
import Stack from "@mui/material/Stack";

const AppStoresButtons = ({ direction = "row", small }) => {
  return (
    <Stack direction={direction} spacing={3}>
      <a href="https://play.google.com/store/apps/details?id=gr.ergologic.ims" target="_blank" rel="noreferrer" title="Get it on Google Play">
        <svg width={small ? "157" : "185"} height={small ? "48" : "57"} viewBox="0 0 185 57" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.933594" y="0.855469" width="183.222" height="55.7633" rx="27.8817" fill="#485776" stroke="#DDDEE1" strokeWidth="1.16574" />
          <path d="M46.287 23.5852L29.219 13.7314C28.4872 13.3086 27.6401 13.4851 27.1133 14.0076L41.2996 28.4872L46.287 23.5852Z" fill="#27EC70" />
          <path d="M27.1137 14.0078C26.8033 14.3153 26.6025 14.7439 26.6025 15.2422V41.072C26.6025 41.6044 26.8318 42.0558 27.1792 42.3676L41.3 28.4888L27.1137 14.0078Z" fill="#3AD5F9" />
          <path d="M27.1787 42.3665C27.7083 42.8406 28.517 42.9873 29.219 42.5815L45.7544 33.0338L41.2995 28.4863L27.1787 42.3665Z" fill="#F60F24" />
          <path d="M51.5879 26.6456L46.2872 23.5859L41.2998 28.4879L45.7547 33.0354L51.5879 29.6682C52.7511 28.9962 52.7511 27.3176 51.5879 26.647V26.6456Z" fill="#FCD831" />
          <path d="M65.5661 16.8221H68.3993V17.0585C68.3993 17.5966 68.3381 18.0736 68.2171 18.4879C68.0989 18.8723 67.8982 19.2311 67.6177 19.5643C66.9813 20.3132 66.1711 20.6876 65.1873 20.6876C64.2035 20.6876 63.4062 20.326 62.7228 19.6013C62.0394 18.8737 61.6963 18.001 61.6963 16.983C61.6963 15.965 62.0437 15.0609 62.7399 14.3377C63.4361 13.6101 64.2818 13.2471 65.2799 13.2471C65.8152 13.2471 66.3164 13.361 66.7805 13.5902C67.2247 13.8194 67.6618 14.191 68.0904 14.7036L67.3529 15.4411C66.7905 14.658 66.1057 14.2665 65.297 14.2665C64.5708 14.2665 63.9629 14.5284 63.4717 15.051C62.9805 15.5649 62.7342 16.2085 62.7342 16.9816C62.7342 17.7547 63.0076 18.4381 63.5557 18.9549C64.0683 19.4347 64.6235 19.6739 65.2215 19.6739C65.7312 19.6739 66.1882 19.4945 66.594 19.1357C66.9998 18.7741 67.2262 18.3398 67.2731 17.8358H65.5632V16.8193L65.5661 16.8221Z" fill="white" />
          <path d="M73.8127 14.4043H71.0634V16.127H73.7329V17.1436H71.0634V19.5383H73.8127V20.5549H70.0283V13.3877H73.8127V14.4043Z" fill="white" />
          <path d="M77.721 14.4043V20.5549H76.6859V14.4043H75.1084V13.3877H79.2928V14.4043H77.721Z" fill="white" />
          <path d="M83.9482 13.3877V20.5549H82.9131V13.3877H83.9482Z" fill="white" />
          <path d="M87.8167 14.4043V20.5549H86.7816V14.4043H85.2041V13.3877H89.3885V14.4043H87.8167Z" fill="white" />
          <path d="M92.3389 16.94C92.3389 15.932 92.692 15.0649 93.3996 14.3402C94.1043 13.6155 94.9515 13.2539 95.9395 13.2539C96.9276 13.2539 97.7548 13.6198 98.4539 14.3488C99.1558 15.0792 99.506 15.9562 99.506 16.9813C99.506 18.0064 99.153 18.8863 98.4496 19.6038C97.742 20.3243 96.8878 20.6859 95.8869 20.6859C95.0013 20.6859 94.2068 20.3656 93.5021 19.7249C92.7261 19.0158 92.3389 18.0876 92.3389 16.94ZM93.3825 16.9542C93.3825 17.7459 93.6373 18.3979 94.147 18.9091C94.6539 19.4202 95.2376 19.675 95.9011 19.675C96.6201 19.675 97.228 19.4145 97.7221 18.8948C98.2161 18.3695 98.4639 17.7288 98.4639 16.9728C98.4639 16.2167 98.2204 15.5675 97.7306 15.0507C97.2451 14.531 96.6443 14.2705 95.9267 14.2705C95.2092 14.2705 94.6098 14.531 94.1186 15.0507C93.6274 15.5647 93.3811 16.1982 93.3811 16.9542H93.3825Z" fill="white" />
          <path d="M69.2694 31.9764H75.7204V32.4491C75.7204 33.5226 75.5937 34.4737 75.3403 35.2994C75.094 36.0654 74.6768 36.7801 74.0916 37.4465C72.7647 38.94 71.0775 39.686 69.0273 39.686C66.9771 39.686 65.3156 38.9642 63.8904 37.5205C62.4653 36.0697 61.752 34.3299 61.752 32.2996C61.752 30.2693 62.4766 28.4668 63.9274 27.0232C65.3768 25.5723 67.1423 24.8477 69.2224 24.8477C70.3386 24.8477 71.3822 25.0755 72.3504 25.5325C73.2758 25.9895 74.1856 26.7299 75.0811 27.7535L73.5449 29.2257C72.3732 27.6638 70.9437 26.8836 69.2594 26.8836C67.7474 26.8836 66.4788 27.4047 65.4551 28.4483C64.4315 29.4734 63.9189 30.7562 63.9189 32.2996C63.9189 33.8429 64.4898 35.2026 65.6317 36.2334C66.6981 37.1902 67.8556 37.6686 69.1028 37.6686C70.1649 37.6686 71.1174 37.3112 71.9631 36.595C72.8074 35.8732 73.2801 35.009 73.3783 34.0038H69.2694V31.9764Z" fill="white" />
          <path d="M77.0635 34.8306C77.0635 33.5735 77.5134 32.5042 78.4132 31.6215C79.313 30.7402 80.4093 30.2988 81.7021 30.2988C82.9948 30.2988 84.1039 30.743 85.0094 31.63C85.9036 32.5185 86.3506 33.6091 86.3506 34.9018C86.3506 36.1946 85.9007 37.3008 85.0009 38.1822C84.0954 39.0578 82.9834 39.4963 81.6665 39.4963C80.3495 39.4963 79.2689 39.0492 78.3861 38.1551C77.5034 37.2738 77.0635 36.1647 77.0635 34.8306ZM79.1194 34.8662C79.1194 35.7361 79.3514 36.4238 79.817 36.9307C80.2925 37.4432 80.9218 37.6995 81.7021 37.6995C82.4823 37.6995 83.1173 37.4461 83.5885 36.9392C84.0584 36.4324 84.2947 35.7561 84.2947 34.9104C84.2947 34.0647 84.0598 33.3884 83.5885 32.8815C83.1116 32.369 82.4837 32.1127 81.7021 32.1127C80.9204 32.1127 80.3111 32.369 79.8341 32.8815C79.3571 33.3941 79.1194 34.0561 79.1194 34.8662Z" fill="white" />
          <path d="M87.6299 34.8306C87.6299 33.5735 88.0798 32.5042 88.9796 31.6215C89.8794 30.7402 90.9757 30.2988 92.2685 30.2988C93.5612 30.2988 94.6689 30.743 95.5759 31.63C96.47 32.5185 96.917 33.6091 96.917 34.9018C96.917 36.1946 96.4671 37.3008 95.5673 38.1822C94.6618 39.0578 93.5499 39.4963 92.2343 39.4963C90.9188 39.4963 89.8353 39.0492 88.954 38.1551C88.0727 37.2738 87.6313 36.1647 87.6313 34.8306H87.6299ZM89.6858 34.8662C89.6858 35.7361 89.9179 36.4238 90.3834 36.9307C90.8604 37.4432 91.4883 37.6995 92.2699 37.6995C93.0515 37.6995 93.6851 37.4461 94.1549 36.9392C94.6262 36.4324 94.8611 35.7561 94.8611 34.9104C94.8611 34.0647 94.6262 33.3884 94.1549 32.8815C93.678 32.369 93.0501 32.1127 92.2699 32.1127C91.4897 32.1127 90.8789 32.369 90.4019 32.8815C89.925 33.3941 89.6872 34.0561 89.6872 34.8662H89.6858Z" fill="white" />
          <path d="M107.027 39.0402C107.027 39.4517 107.013 39.8133 106.987 40.1266C106.96 40.4398 106.923 40.7146 106.875 40.9538C106.733 41.6087 106.452 42.1725 106.035 42.6438C105.249 43.5493 104.167 44.002 102.79 44.002C101.628 44.002 100.672 43.6888 99.9213 43.0638C99.1468 42.4202 98.6998 41.529 98.5802 40.3914H100.6C100.677 40.8199 100.807 41.1517 100.985 41.3837C101.402 41.9262 102.01 42.1967 102.809 42.1967C104.281 42.1967 105.016 41.294 105.016 39.4887V38.2728C104.217 39.0886 103.297 39.4973 102.253 39.4973C101.067 39.4973 100.096 39.0687 99.339 38.2102C98.5759 37.3403 98.1943 36.2525 98.1943 34.9484C98.1943 33.6442 98.5489 32.6006 99.2579 31.7122C100.021 30.7711 101.028 30.2998 102.279 30.2998C103.375 30.2998 104.287 30.7084 105.014 31.5242V30.5404H107.026V39.0402H107.027ZM105.097 34.9113C105.097 34.0656 104.87 33.3893 104.418 32.8825C103.959 32.3642 103.371 32.1051 102.656 32.1051C101.893 32.1051 101.292 32.3885 100.851 32.9537C100.452 33.4605 100.253 34.1155 100.253 34.9199C100.253 35.7243 100.452 36.3621 100.851 36.869C101.287 37.4228 101.888 37.7005 102.656 37.7005C103.425 37.7005 104.033 37.42 104.48 36.8605C104.892 36.3536 105.097 35.7044 105.097 34.9113Z" fill="white" />
          <path d="M110.704 24.1494V39.2455H108.693V24.1494H110.704Z" fill="white" />
          <path d="M127.492 33.687V39.4945H125.334V25.1943H127.779C128.976 25.1943 131.626 25.2783 132.238 25.4449C132.856 25.6115 133.4 25.9261 133.868 26.3903C134.689 27.1933 135.1 28.2056 135.1 29.4286C135.1 30.737 134.661 31.7749 133.784 32.5409C132.907 33.3069 129.98 33.6899 128.49 33.6899H127.49L127.492 33.687ZM127.492 31.6867H128.298C130.28 31.6867 133.017 31.6867 133.017 29.3987C133.017 27.1107 130.25 27.1847 128.206 27.1847H127.492V31.6867Z" fill="white" />
          <path d="M138.162 24.5771V39.3942H136.188V24.5771H138.162Z" fill="white" />
          <path d="M152.324 37.6971L147.826 30.1953H150.116L153.385 35.654L156.259 30.1953H158.487L151.679 43.3907H149.425L152.322 37.6985L152.324 37.6971Z" fill="white" />
          <path d="M116.546 32.1156C117.427 32.1156 118.189 32.7477 118.555 33.6646C118.266 32.6296 117.595 32.1113 116.542 32.1113C116.297 32.1113 116.069 32.1483 115.854 32.2224C115.639 32.2964 115.444 32.4046 115.269 32.5441C115.092 32.6837 114.943 32.8531 114.818 33.0496C114.717 33.2076 114.635 33.3856 114.574 33.5778C114.953 32.7065 115.695 32.1142 116.548 32.1142L116.546 32.1156Z" fill="white" />
          <path d="M119.018 36.019C118.607 36.6626 118.241 37.1039 117.919 37.3417C117.591 37.5795 117.177 37.6991 116.677 37.6991C116.034 37.6991 115.506 37.4912 115.096 37.074C114.97 36.9445 114.862 36.8021 114.771 36.6455L118.776 34.6195L120.551 33.7211C120.436 33.0491 120.215 32.4682 119.889 31.9756C119.783 31.8161 119.667 31.6652 119.538 31.5242C118.793 30.7084 117.779 30.2998 116.499 30.2998C115.219 30.2998 114.211 30.7198 113.443 31.5598C112.679 32.4127 112.298 33.526 112.298 34.9028C112.298 36.2796 112.691 37.4058 113.478 38.2458C114.264 39.0801 115.308 39.4973 116.606 39.4973C117.059 39.4973 117.479 39.4488 117.866 39.3549C118.253 39.2595 118.614 39.1086 118.948 38.9035C119.281 38.6985 119.595 38.4366 119.886 38.1176C120.178 37.7987 120.456 37.4157 120.718 36.9687L119.019 36.0219L119.018 36.019ZM114.573 33.5801C114.636 33.3865 114.715 33.21 114.816 33.0519C114.942 32.8554 115.091 32.6874 115.268 32.5465C115.444 32.407 115.639 32.2988 115.853 32.2247C116.068 32.1507 116.297 32.1137 116.541 32.1137C117.264 32.1137 117.805 32.3571 118.168 32.8455L114.308 34.7989C114.308 34.8207 114.308 34.8207 114.308 34.7989C114.321 34.3604 114.415 33.946 114.573 33.5816V33.5801Z" fill="white" />
          <path d="M139.639 37.0013C139.639 34.8472 141.992 32.9038 145.616 34.2891C145.611 33.0163 145.423 31.7036 143.769 31.7036C142.597 31.7036 141.715 32.0737 140.92 32.592L140.077 30.929C141.01 30.3311 142.431 29.6377 144.057 29.6377C146.528 29.6377 147.575 31.0401 147.575 33.5018V39.5684H145.986L145.842 38.3867H145.957C144.992 39.1712 143.864 39.8133 142.607 39.8133C140.912 39.8133 139.639 38.7853 139.639 37.0013ZM145.516 36.5059C145.523 35.9762 144.858 35.4096 143.921 35.3398C142.315 35.2202 141.319 35.7655 141.319 36.8846C141.319 37.7773 141.955 38.3311 143.094 38.1218C144.057 37.9453 145.507 37.3075 145.517 36.5073L145.516 36.5059Z" fill="white" />
          <path d="M105.006 13.388V18.159V19.0233L101.72 13.3154H100.863V13.5247V15.7344V20.5538H101.72V15.7344V14.883L105.006 20.578H105.864V20.3673V18.159V13.388H105.006Z" fill="white" />
        </svg>
      </a>
      <a href="/" target="_blank" rel="noreferrer" title="Download on the App Store">
        <svg width={small ? "157" : "185"} height={small ? "48" : "57"} viewBox="0 0 184 57" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.0908203" y="0.855469" width="183.222" height="55.7633" rx="27.8817" fill="#485776" stroke="#DDDEE1" strokeWidth="1.16574" />
          <path d="M49.076 29.1085C49.0569 26.1038 50.5849 23.5987 52.6124 23.0889C50.9131 21.0335 48.3157 20.2234 45.9175 20.2395C45.0942 20.2454 44.3046 20.4739 43.5691 20.8914C41.9445 21.8143 39.9843 21.8261 38.348 20.9251C37.6081 20.5178 36.8156 20.2996 35.9908 20.304C31.9987 20.3303 27.4881 22.6479 27.5306 29.1348C27.5731 35.6217 32.1526 43.8242 36.1446 43.7978C37.0632 43.7919 37.9378 43.5077 38.7435 42.995C40.1821 42.0779 42.0119 42.0662 43.4622 42.9642C44.2738 43.4652 45.1528 43.7377 46.0713 43.7319C48.8328 43.7143 51.8243 39.753 53.3918 35.1661C51.0273 35.1486 49.0994 32.4501 49.0774 29.1085H49.076Z" fill="white" />
          <path d="M45.1623 17.4092C43.4966 19.485 40.3791 19.7487 40.3791 19.7487C40.3791 19.7487 39.9631 16.6488 41.6302 14.573C43.2959 12.4971 46.4134 12.2334 46.4134 12.2334C46.4134 12.2334 46.8294 15.3333 45.1623 17.4092Z" fill="white" />
          <path d="M64.6025 12.4723L67.5032 12.4532C69.6245 12.4386 71.4865 13.8171 71.5055 16.7471C71.5246 19.7298 70.0127 21.5332 67.3787 21.5507L64.6626 21.5683L64.6025 12.4723ZM65.5196 20.827L67.3157 20.8153C69.5615 20.8007 70.6177 19.2976 70.6016 16.791C70.5855 14.3797 69.1733 13.1828 67.2791 13.196L65.4698 13.2077L65.5196 20.8285V20.827Z" fill="white" />
          <path d="M78.4069 18.0595C78.4201 19.9683 77.6231 21.593 75.559 21.6076C73.6003 21.6208 72.7726 19.9537 72.7608 18.1254C72.7491 16.3557 73.6984 14.7677 75.5941 14.7559C77.3682 14.7442 78.3937 16.1125 78.4069 18.0609V18.0595ZM73.6281 18.1225C73.6384 19.687 74.3181 20.8883 75.619 20.8795C76.9961 20.8707 77.5411 19.6944 77.5294 18.0682C77.5191 16.5872 76.8584 15.4752 75.5531 15.484C74.2712 15.4928 73.6179 16.5959 73.6281 18.1225Z" fill="white" />
          <path d="M80.088 14.8574C80.9508 17.906 81.4548 19.7153 81.6364 20.6133H81.6511C81.8152 19.8222 82.1551 18.6839 83.204 14.8369L84.0551 14.8311C85.1084 18.5799 85.4703 19.8178 85.589 20.5371H85.6021C85.7486 19.7871 86.172 18.3016 87.1492 14.8105L88.0076 14.8047L86.0284 21.4293L85.1231 21.4352C84.4917 19.204 83.8207 16.8776 83.6024 15.8448H83.5893C83.4135 16.8425 82.7967 18.9843 82.0818 21.4542L81.1325 21.4601L79.1914 14.8618L80.0865 14.856L80.088 14.8574Z" fill="white" />
          <path d="M89.145 16.8694C89.1362 15.4865 89.1201 15.0763 89.1055 14.7965L89.9273 14.7906C89.9434 14.9195 89.9771 15.633 89.9669 15.8952C90.2379 15.2711 90.8136 14.6529 91.9475 14.6456C93.1107 14.6382 94.0615 15.2491 94.0747 17.1346L94.1025 21.3742L93.2616 21.3801L93.2338 17.2459C93.2264 16.2028 92.8441 15.403 91.7966 15.4088C90.4679 15.4176 89.9713 16.4534 89.9815 17.9271L90.005 21.402L89.1743 21.4079L89.145 16.8694Z" fill="white" />
          <path d="M96.1099 21.3627L96.0469 11.7891L96.8775 11.7832L96.9405 21.3568L96.1099 21.3627Z" fill="white" />
          <path d="M104.1 17.8905C104.113 19.7994 103.316 21.424 101.252 21.4387C99.2936 21.4519 98.4659 19.7847 98.4542 17.9564C98.4425 16.1867 99.3918 14.5987 101.287 14.587C103.062 14.5753 104.087 15.9436 104.1 17.892V17.8905ZM99.3215 17.9535C99.3317 19.5181 100.011 20.7194 101.312 20.7106C102.689 20.7018 103.234 19.5254 103.223 17.8993C103.212 16.4182 102.552 15.3063 101.246 15.3151C99.9646 15.3239 99.3112 16.427 99.3215 17.9535Z" fill="white" />
          <path d="M110.054 19.8971C110.057 20.3571 110.11 20.968 110.166 21.2698L109.386 21.2742C109.344 21.0339 109.291 20.6911 109.285 20.1681C109.045 20.883 108.362 21.3987 107.239 21.406C105.631 21.4163 105.161 20.3043 105.155 19.4195C105.149 18.498 105.715 17.3436 108.112 17.3275C108.58 17.3246 108.898 17.3216 109.213 17.3202L109.209 16.617C109.205 15.9578 109.018 15.2238 107.766 15.2326C106.73 15.2399 106.343 15.7028 106.219 16.5247L105.399 16.5306C105.501 15.3996 106.14 14.5528 107.797 14.5411C109.111 14.5323 110.021 15.089 110.031 16.5452L110.053 19.8941L110.054 19.8971ZM109.24 17.9809C108.97 17.9823 108.536 17.9853 108.1 17.9882C106.342 17.9999 106.014 18.7148 106.018 19.3785C106.023 20.0963 106.464 20.6823 107.362 20.6764C108.759 20.6677 109.25 19.7183 109.24 18.1142V17.9809Z" fill="white" />
          <path d="M116.935 11.6514L116.986 19.5476C116.991 20.1321 116.997 20.8089 117.023 21.225L116.201 21.2308C116.185 21.058 116.165 20.6317 116.162 20.1819C115.807 21.0228 115.255 21.3568 114.156 21.3642C112.433 21.3759 111.584 19.9094 111.57 17.9683C111.559 16.0917 112.509 14.5125 114.306 14.5007C115.403 14.4934 115.938 14.9505 116.128 15.3534L116.103 11.6572L116.934 11.6514H116.935ZM112.441 17.9185C112.452 19.6663 113.257 20.6229 114.335 20.6156C115.869 20.6053 116.181 19.407 116.17 17.7574C116.157 15.7621 115.532 15.242 114.374 15.2493C113.183 15.2567 112.43 16.2441 112.441 17.92V17.9185Z" fill="white" />
          <path d="M127.455 17.7372C127.468 19.6461 126.671 21.2707 124.607 21.2854C122.648 21.2985 121.82 19.6314 121.809 17.8031C121.797 16.0334 122.746 14.4454 124.642 14.4337C126.416 14.4219 127.442 15.7902 127.455 17.7387V17.7372ZM122.676 17.8002C122.686 19.3648 123.366 20.5661 124.667 20.5573C126.044 20.5485 126.589 19.3721 126.577 17.746C126.567 16.2649 125.906 15.153 124.601 15.1618C123.319 15.1706 122.666 16.2737 122.676 17.8002Z" fill="white" />
          <path d="M128.987 16.6087C128.978 15.2257 128.962 14.8155 128.947 14.5357L129.769 14.5299C129.785 14.6588 129.819 15.3722 129.809 15.6345C130.08 15.0104 130.655 14.3922 131.789 14.3848C132.953 14.3775 133.903 14.9884 133.916 16.8738L133.944 21.1135L133.103 21.1193L133.076 16.9852C133.068 15.9421 132.686 15.1422 131.638 15.1481C130.31 15.1569 129.813 16.1926 129.823 17.6664L129.847 21.1413L129.016 21.1472L128.987 16.6087Z" fill="white" />
          <path d="M138.085 14.4772L139.194 14.4699L139.181 12.501L140.009 12.4951L140.022 14.464L141.414 14.4553L141.418 15.1702L140.027 15.179L140.056 19.5973C140.06 20.1877 140.211 20.4368 140.784 20.4324C140.974 20.4324 141.21 20.4075 141.341 20.3811L141.345 21.0462C141.121 21.1326 140.784 21.159 140.555 21.1605C139.715 21.1663 139.235 20.8572 139.228 19.8112L139.197 15.1848L138.088 15.1921L138.084 14.4772H138.085Z" fill="white" />
          <path d="M143.378 11.4785L143.404 15.4501C143.727 14.7776 144.402 14.303 145.417 14.2971C146.295 14.2913 147.498 14.7278 147.511 16.6455L147.54 21.0258L146.714 21.0316L146.686 16.8081C146.679 15.6859 146.213 15.0545 145.19 15.0618C144.045 15.0692 143.407 15.8354 143.418 17.2476L143.442 21.0536L142.612 21.0595L142.549 11.4858L143.379 11.48L143.378 11.4785Z" fill="white" />
          <path d="M149.913 17.8407C149.913 19.357 150.578 20.4059 151.816 20.3971C152.984 20.3898 153.337 19.543 153.47 19.1768L154.294 19.1709C154.121 19.8932 153.532 21.1135 151.768 21.1252C149.717 21.1384 149.069 19.4434 149.058 17.6767C149.047 16.0593 149.822 14.2662 151.78 14.253C153.795 14.2398 154.384 15.8645 154.393 17.3236C154.393 17.3397 154.393 17.7924 154.393 17.8114L149.913 17.8407ZM153.56 17.1537C153.514 15.8865 153 14.9635 151.758 14.9708C150.326 14.9796 150 16.3142 149.935 17.1771L153.56 17.1537Z" fill="white" />
          <path d="M68.3802 36.8813L66.8346 41.2836L64.9023 41.2967L70.021 26.6206L72.4118 26.6045L77.9465 41.2103L75.8779 41.2235L74.224 36.8418L68.3802 36.8798V36.8813ZM73.7142 35.1834C72.2932 31.3437 71.5123 29.3044 71.1886 28.1911H71.168C70.8179 29.4363 69.977 31.9165 68.8739 35.2156L73.7142 35.1834Z" fill="white" />
          <path d="M81.7931 39.8672L81.8298 45.3799L79.9956 45.3916L79.915 33.1297C79.9092 32.2596 79.9033 31.3557 79.877 30.5265L81.6686 30.5148C81.7126 30.8693 81.7389 31.5153 81.7448 32.1922C82.3161 31.1125 83.3929 30.2511 85.2197 30.2394C87.6882 30.2232 89.4945 32.2801 89.515 35.5265C89.5399 39.3369 87.4963 41.401 84.9121 41.4186C83.1863 41.4303 82.281 40.7125 81.7946 39.8672H81.7931ZM87.6164 35.61C87.6018 33.348 86.5616 31.8069 84.7392 31.8186C82.5286 31.8332 81.6642 33.2367 81.6803 35.8312C81.6965 38.3524 82.4231 39.8789 84.6513 39.8642C86.6012 39.8511 87.6325 38.2381 87.615 35.61H87.6164Z" fill="white" />
          <path d="M93.7892 39.7881L93.8259 45.3008L91.9917 45.3125L91.9111 33.0506C91.9053 32.1805 91.8994 31.2766 91.873 30.4474L93.6647 30.4357C93.7087 30.7902 93.735 31.4362 93.7409 32.1131C94.3122 31.0334 95.389 30.172 97.2158 30.1603C99.6843 30.1441 101.491 32.201 101.511 35.4474C101.536 39.2578 99.4924 41.3219 96.9082 41.3395C95.1824 41.3512 94.2771 40.6334 93.7907 39.7881H93.7892ZM99.6125 35.5309C99.5979 33.2689 98.5577 31.7278 96.7353 31.7395C94.5247 31.7541 93.6603 33.1576 93.6764 35.7521C93.6925 38.2733 94.4192 39.7998 96.6474 39.7851C98.5973 39.772 99.6286 38.159 99.6111 35.5309H99.6125Z" fill="white" />
          <path d="M110.521 36.9201C110.93 38.6737 112.137 39.6142 114.258 39.601C116.542 39.5864 117.44 38.5096 117.43 37.1252C117.42 35.6559 116.693 34.8384 113.753 34.1469C110.297 33.3309 109.091 32.1575 109.078 30.0992C109.064 27.9252 110.656 26.1408 113.924 26.1203C117.411 26.0969 118.86 28.1039 119.069 30.0245L117.093 30.0377C116.822 28.7368 116 27.6732 113.864 27.6864C112.081 27.6981 111.087 28.5068 111.095 29.9029C111.104 31.277 111.935 31.8132 114.504 32.4183C118.687 33.4071 119.434 35.0274 119.447 36.9069C119.463 39.2685 117.724 41.1305 114.144 41.1539C110.461 41.1788 108.863 39.1762 108.545 36.9318L110.521 36.9187V36.9201Z" fill="white" />
          <path d="M120.527 30.2596L122.373 30.2478L122.353 27.0996L124.196 27.0879L124.216 30.2361L126.572 30.22L126.582 31.7231L124.226 31.7392L124.267 37.9902C124.275 39.0274 124.53 39.5578 125.495 39.5504C125.751 39.549 126.134 39.5329 126.425 39.4552L126.434 40.8426C125.98 41.0154 125.349 41.0462 124.84 41.0506C123.18 41.0608 122.439 40.2068 122.427 38.4341L122.383 31.7509L120.538 31.7626L120.527 30.2596Z" fill="white" />
          <path d="M137.67 35.4616C137.69 38.5365 135.944 41.0827 132.681 41.1032C129.624 41.1237 127.759 38.7695 127.738 35.5626C127.718 32.4115 129.513 29.9474 132.728 29.9269C135.692 29.9078 137.648 32.1126 137.67 35.4616ZM129.636 35.5348C129.65 37.8758 130.83 39.5752 132.749 39.562C134.701 39.5488 135.787 37.9227 135.772 35.5011C135.756 33.1 134.655 31.4534 132.653 31.4666C130.657 31.4798 129.621 33.0986 129.637 35.5348H129.636Z" fill="white" />
          <path d="M140.055 33.031C140.045 31.5397 140.026 30.6988 140.016 30.1319L141.822 30.1201C141.853 30.419 141.886 31.257 141.894 32.2986C142.442 30.8512 143.709 29.9107 145.473 29.8447L145.484 31.6818C143.296 31.7609 141.898 32.9139 141.916 35.6109L141.949 40.7925L140.106 40.8042L140.055 33.0325V33.031Z" fill="white" />
          <path d="M148.417 35.7463C148.445 37.9174 149.546 39.4512 151.359 39.4395C153.046 39.4278 153.587 38.5546 153.924 37.7723L155.78 37.7606C155.37 39.1304 154.236 40.9616 151.312 40.9806C147.957 41.0026 146.57 38.2851 146.552 35.5368C146.531 32.3636 148.114 29.8234 151.357 29.8029C154.8 29.7809 155.945 32.535 155.96 34.8102C155.961 35.1339 155.964 35.4064 155.938 35.695L148.417 35.7448V35.7463ZM154.088 34.4102C154.056 32.6552 153.163 31.2503 151.339 31.262C149.463 31.2737 148.645 32.5863 148.471 34.4468L154.088 34.4102Z" fill="white" />
        </svg>
      </a>
    </Stack>
  )
}

export default AppStoresButtons;
