import React from "react";
import Stack from "@mui/material/Stack";

import { useWizard } from "../WizardContext";
import QuestionTitle from "../../elements/QuestionTitle";
import AmountField from "../../elements/AmountField";
import InfoMessage from "../../elements/InfoMessage";

const titles = {
  "1": "What is the value of the property you want to buy/search for?",
  "2": "What is the value of the plot you want to buy?",
  "3": "What is the construction cost?",
  "4": "What is the value of the property you want to complete?",
  "5": "What is the cost/budget for the required work?",
}

const HaveProperty = () => {
  const { responses } = useWizard();

  return (
    <>
      <QuestionTitle
        title={titles[responses["1"]?.AnswerId || "1"]}
        main
      />
      <Stack spacing={3}>
        <AmountField itemId="3" />
        {responses["2"]?.AnswerId === "7" && (
          <InfoMessage
            message="Haven't found a property? IMS can help you find one through its extensive network of collaborating real estate agents."
          />
        )}
      </Stack>
    </>
  );
}

export default HaveProperty;
