import React from "react";
import Box from "@mui/material/Box";

import { useWizard } from "../wizard/WizardContext";
import useUserData from "../../../hooks/user/useUserData";
import { textTranslate } from "../../../helpers/helpers";

import WarningMessage from "./WarningMessage";

const ValidationWarning = () => {
  const { validation } = useWizard();
  const { language } = useUserData();

  if (validation?.message !== "Error") return null;

  return (
    <Box sx={{ mt: 3 }}>
      <WarningMessage
        message={textTranslate(language)({
          el: validation.DescriptionEl,
          en: validation.DescriptionEn,
        })}
      />
    </Box>
  );
}

export default ValidationWarning;
