import React from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import useUserData from "../../../hooks/user/useUserData";
import { useWizard } from "../wizard/WizardContext";
import { textTranslate } from "../../../helpers/helpers";

const RadioOptions = ({ itemId, options }) => {
  const { language } = useUserData();
  const { responses, updateResponse } = useWizard();

  return (
    <Stack spacing={2}>
      {options.map((option) => {
        const { AnswerId, AnswerDescriptionEl, AnswerDescriptionEn } = option;
        const answerDescription = textTranslate(language)({
          el: AnswerDescriptionEl,
          en: AnswerDescriptionEn,
        });

        return (
          <Box
            key={AnswerId}
            component="label"
            sx={{
              p: "16px",
              border: "1px solid #dddee1",
              borderRadius: "8px",
              cursor: "pointer",
              "&:hover": {
                borderColor: "#000000"
              },
              "&:has(input:checked)": {
                borderColor: "#0bc1b6"
              }
            }}
          >
            <input
              type="radio"
              name={itemId}
              value={AnswerId}
              onChange={() => updateResponse(
                itemId,
                {
                  AnswerId: AnswerId,
                  AnswerDescription: answerDescription
                }
              )}
              checked={responses[itemId]?.AnswerId === AnswerId}
              style={{ display: "none" }}
            />
            <Typography>
              {answerDescription}
            </Typography>
          </Box>
        );
      })}
    </Stack>
  );
}

export default RadioOptions;
