//@flow
import React, {useEffect} from 'react'
import useUserData from "../../hooks/user/useUserData";
import moment from "moment";
import 'moment/locale/el'
import useUserAction from "../../hooks/user/useUserAction";
import './main.css';
import {AppBar, Box} from '@mui/material';
import UnauthenticatedRouter from "../../routers/unauthenticatedRouter";
import Content from "./content";
import { Capacitor } from '@capacitor/core';
import Header from '../../thinkPlus/components/elements/Header';
// import {IonContent, IonHeader, IonToolbar} from "@ionic/react";

const Main = () => {
	const {getUserPermissions} = useUserAction()
	const {isLoggedIn, language, alterPasswordOnLogin} = useUserData()
	const platform = Capacitor.getPlatform()
	useEffect(() => {
		moment.locale(language);
	}, [language])
	useEffect(() => {
		if (isLoggedIn) {
			getUserPermissions()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getUserPermissions, isLoggedIn])
	
	if (!isLoggedIn || (isLoggedIn && alterPasswordOnLogin)) return (
		<>
		<div>
			{/* <AppBar position={'fixed'} elevation={0}> */}
				{/* <IonHeader className="ion-no-border"> */}
					<Header />
				{/* </IonHeader> */}
			{/* </AppBar> */}
			{/* <IonContent> */}
				{/* <Box pt={8}> */}
					<UnauthenticatedRouter/>
				{/* </Box> */}
			{/* </IonContent> */}
			</div>
		</>
	)
	return <Content/>
}

export default Main