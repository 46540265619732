import React from "react";
import Stack from "@mui/material/Stack";

import QuestionTitle from "../../elements/QuestionTitle";
import AmountField from "../../elements/AmountField";
import InfoMessage from "../../elements/InfoMessage";

const LoanAmount = () => {
  return (
    <>
      <QuestionTitle
        title="What loan amount would you like to apply for?"
        main
      />
      <Stack spacing={3}>
        <AmountField itemId="10" />
        <InfoMessage
          title="Financing percentage or otherwise LTV (Loan to Value)"
          message="ltv_description"
        />
      </Stack>
    </>
  );
}

export default LoanAmount;
