import React from "react";

import QuestionTitle from "../../elements/QuestionTitle";
import NumberField from "../../elements/NumberField";

const Age = () => {
  return (
    <>
      <QuestionTitle title="What is your age?" main />
      <NumberField itemId="9" />
    </>
  );
}

export default Age;
