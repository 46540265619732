import React from "react";

import QuestionTitle from "../../elements/QuestionTitle";
import RadioOptions from "../../elements/RadioOptions";
import { useWizard } from "../WizardContext";

const HaveProperty = () => {
  const { data } = useWizard();

  return (
    <>
      <QuestionTitle title="Have you found a property?" main />
      <RadioOptions
        itemId="2"
        options={data?.filter((data) => data.ItemId === "2")}
      />
    </>
  );
}

export default HaveProperty;
