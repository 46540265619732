import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import InputAdornment from '@mui/material/InputAdornment';
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import LoadingButton from "@mui/lab/LoadingButton";
import * as yup from "yup";
import { ErrorMessage, FormikProvider, useFormik } from 'formik';
import FormHelperText from "@mui/material/FormHelperText";
import Dialog from "@mui/material/Dialog";
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Box, Select } from "@mui/material";
import { MenuItem } from "@mui/material";

import PasswordIcon from "../../../../assets/images/icons/login/passwordIcon";
import { WIZARD } from "../../../locales/namespaces";
import { useWizard } from "../WizardContext";
import OTP from "../../elements/OtpField";
import { OTP_LENGTH } from "../constants";
import ArrowDown2 from "../../../../assets/images/icons/arrowDown2";
import { textTranslate } from "../../../../helpers/helpers";
import useUserData from "../../../../hooks/user/useUserData";

const PasswordAdornment = () => (
  <InputAdornment position="start">
    <PasswordIcon />
  </InputAdornment>
);

const CtaForm = () => {
  const [showOtp, setShowOtp] = useState(false);
  const [otp, setOtp] = useState("");

  const { language } = useUserData();
  const { t } = useTranslation(WIZARD);
  const { summary, validateUser, importUser, data } = useWizard();

  const phoneCodes = data.filter((item) => item.ItemId === "21");

  const enablePassword = summary.approval_level > 1;

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone_code: "76",
      phone: "",
      password: "",
      confirm_password: "",
      accept_terms: false,
    },
    validationSchema: yup.object({
      name: yup.string().required(t("Required")),
      email: yup.string().email().required(t("Required")),
      phone_code: yup.string().required(t("Required")),
      phone: yup.string().required(t("Required")),
      password: yup.string().when([], {
        is: () => enablePassword,
        then: (schema) => schema.required(t("Required")),
      }),
      confirm_password: yup.string().when([], {
        is: () => enablePassword,
        then: (schema) => schema
          .oneOf([yup.ref("password")], t("Passwords do not match"))
          .required(t("Required")),
      }),
      accept_terms: yup.boolean().oneOf([true], t("Required"))
    }),
    onSubmit: (values) => {
      if (enablePassword && !otp) {
        return validateUser({
          email: values.email,
          onSuccess: () => setShowOtp(true),
        });
      }

      const selectedPhoneCode = phoneCodes.find(
        (code) => code.AnswerId === values.phone_code
      );

      const items = [
        { "ItemId": "17", "ItemDescription": "Ονοματεπώνυμο", "AnswerId": "", "AnswerDescription": values.name },
        { "ItemId": "18", "ItemDescription": "Email", "AnswerId": "", "AnswerDescription": values.email },
        { "ItemId": "19", "ItemDescription": "Κινητό τηλέφωνο", "AnswerId": "", "AnswerDescription": values.phone },
        {
          "ItemId": "21",
          "ItemDescription": "Κωδικός τηλεφώνου",
          "AnswerId": selectedPhoneCode.AnswerId.toString(),
          "AnswerDescription": textTranslate(language)({
            el: selectedPhoneCode.AnswerDescriptionEl,
            en: selectedPhoneCode.AnswerDescriptionEn,
          }),
        },
      ];

      if (enablePassword) {
        items.push({
          "ItemId": "20",
          "ItemDescription": "Κωδικός",
          "AnswerId": "",
          "AnswerDescription": values.password,
        });
      }

      return importUser({ items, code: otp });
    }
  });

  useEffect(() => {
    if (otp.length === OTP_LENGTH) {
      formik.handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otp]);

  return (
    <FormikProvider value={formik}>
      <Stack component="form" spacing={2} onSubmit={formik.handleSubmit}>
        <FormControl sx={{ gap: 1 }}>
          <Typography component={FormLabel} htmlFor="wizard.name">
            {t("Full name")}
          </Typography>
          <TextField
            id="wizard.name"
            name="name"
            sx={{ borderRadius: "8px" }}
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </FormControl>
        <FormControl sx={{ gap: 1 }}>
          <Typography component={FormLabel} htmlFor="wizard.email">
            {t("Email")}
          </Typography>
          <TextField
            type="email"
            id="wizard.email"
            name="email"
            fullWidth
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </FormControl>
        <Typography>
          {t("Mobile phone")}
        </Typography>
        <Box sx={{ display: "flex", gap: 1 }} style={{ marginTop: "8px" }}>
          <FormControl>
            <Select
              id="wizard.phone_code"
              name="phone_code"
              size="small"
              IconComponent={ArrowDown2}
              value={formik.values.phone_code}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              sx={{
                py: 1,
                px: 0.5,
                ".MuiSelect-icon": {
                  right: "14px",
                }
              }}
            >
              {phoneCodes.map((option) => {
                const { AnswerId, AnswerDescriptionEl, AnswerDescriptionEn } = option;
                const answerDescription = textTranslate(language)({
                  el: AnswerDescriptionEl,
                  en: AnswerDescriptionEn,
                });

                return (
                  <MenuItem key={AnswerId} value={AnswerId}>
                    <Typography>
                      {answerDescription}
                    </Typography>
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
          <FormControl sx={{ flex: "1", gap: 1 }}>
            <TextField
              type="tel"
              id="wizard.phone"
              name="phone"
              fullWidth
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
            />
          </FormControl>
        </Box>
        {enablePassword && (
          <>
            <Typography sx={{ py: 2 }}>
              {t("By setting a password, you can create an account to track your application")}
            </Typography>
            <FormControl sx={{ gap: 1 }} >
              <Typography component={FormLabel} htmlFor="wizard.password">
                {t("Create a password")}
              </Typography>
              <TextField
                type="password"
                id="wizard.password"
                name="password"
                InputProps={{
                  startAdornment: <PasswordAdornment />,
                }}
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.password
                  && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
            </FormControl>
            <FormControl sx={{ gap: 1 }}>
              <Typography component={FormLabel} htmlFor="wizard.confirm_code">
                {t("Confirm password")}
              </Typography>
              <TextField
                type="password"
                id="wizard.confirm_code"
                name="confirm_password"
                InputProps={{
                  startAdornment: <PasswordAdornment />,
                }}
                value={formik.values.confirm_password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.confirm_password
                  && Boolean(formik.errors.confirm_password)
                }
                helperText={
                  formik.touched.confirm_password
                  && formik.errors.confirm_password
                }
              />
            </FormControl>
          </>
        )}
        <FormControl>
          <FormControlLabel
            label={t("I accept the Privacy Statement")}
            labelPlacement="end"
            control={
              <Checkbox
                size="medium"
                name="accept_terms"
                checked={formik.values.accept_terms}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            }
            error={
              formik.touched.accept_terms
              && Boolean(formik.errors.accept_terms)
            }
          />
          <FormHelperText error>
            <ErrorMessage name={"accept_terms"} />
          </FormHelperText>
        </FormControl>
        <div>
          <LoadingButton
            type="submit"
            variant="contained"
            color="info"
            sx={{
              p: "12px 32px",
              ".MuiLoadingButton-loadingIndicator": {
                color: "#fff",
              }
            }}
            disabled={formik.isSubmitting}
            loading={formik.isSubmitting}
          >
            {t("Submit")}
          </LoadingButton>
        </div>
      </Stack>
      <Dialog open={showOtp} onClose={setShowOtp}>
        <DialogContent sx={{ p: 8 }}>
          <DialogContentText
            sx={{
              mb: 4,
              fontSize: "18px",
              lineHeight: "24px",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            {t("Enter the code you received in your email")}
          </DialogContentText>
          <OTP value={otp} onChange={setOtp} length={OTP_LENGTH} />
        </DialogContent>
      </Dialog>
    </FormikProvider>
  );
}

export default CtaForm;
